import { useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "../../components/Button";
import { Flex } from "../../components/Flex";
import Topbar from "../../components/Topbar";
import { ROUTE } from "../../routes";
import { Breadcrumb } from "../../components/Breadcrumbs";
import { Modal } from "../../components/Modal";
import { useNavigate, useParams } from "react-router-dom";
import { Comments } from "../../components/Comments";
import { Card } from "../../components/Card";
import { H3 } from "../../components/Heading";
import { Box } from "../../components/Box";
import { IconButtonDropdown } from "../../components/IconButtonDropdown";
import { IconButtonDropdownOption } from "../../components/IconButtonDropdown/IconButtonDropdownOption";
import { VscDebugRestart } from "react-icons/vsc";
import resendInviteIcon from "../../assets/resend-invite-icon.svg";
import commentsIcon from "../../assets/comments-icon.svg";
import downloadIcon from "../../assets/download-arrow.svg";
import { Image } from "../../components/Image";
import { FaTrash } from "react-icons/fa";
import { ActionItem } from "./ActionItem";
import { ProgressBar } from "../../components/ProgressBar";
import { Paragraph } from "../../components/Paragraph";
import { calculatePercentage } from "../../utils/number";
import { useIndividual } from "../../data/useIndividual";
import { useCreateIndividualsInvites } from "../../data/useCreateIndividualsInvites";
import { clearAll, notify } from "../../utils/notify";
import { handleQueryError, showError } from "../../utils/error-handling";
import { useMandate } from "../../data/useMandate";
import { buildName, capitalise } from "../../utils/string";
import { DateTime } from "luxon";
import { queryClient } from "../../queryClient";
import { CheckSection } from "./CheckSection";
import { Loader } from "../../components/Loader";
import { useDeleteIndividual } from "../../data/useDeleteIndividual";
import {
  MandateStatus,
  useMandateStatuses,
} from "../../context/MandateStatusContext";
import { useAuth } from "../../context/AuthContext";
import { axios } from "../../data/axios";
import { env } from "../../utils/env";
import { Geolocation } from "../../components/Geolocation";
import theme from "../../theme";
import { checksColor, checksIcons } from "./helper";
import { AdditionalDocuments } from "../../components/AdditionalDocuments";
import { useAdditionalDocuments } from "../../data/useAdditionalDocuments";

const Heading = styled(H3)`
  ${({ theme: { fontSizes } }) => `
    font-size: ${fontSizes[2]};
    word-break: break-all;
  `}
`;

const Container = styled.div`
  ${({ theme: { space } }) => `
    padding: ${space[4]};
    height: calc(100vh - 165px);
    overflow-y: auto;
    padding-bottom: ${space[5]}
  `}
`;

const ChecksBar = styled.div<{
  variant: "green" | "orange" | "gray";
}>`
  ${({ theme: { colors, space }, variant }) => `
    height: 5px;
    width: 100%;
    border-radius: 12px;
    margin-top: ${space[2]};
    margin-bottom: ${space[2]};
    background-color: ${
      variant === "green"
        ? colors.green.primary
        : variant === "orange"
        ? colors.orange[50]
        : colors.gray[40]
    };
  `}
`;

const ViewBox = styled(Box)`
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
  &.expanded {
    max-height: 1000px; /* Large enough value to show all content */
    opacity: 1;
  }
`;
const CountBox = styled(Box)`
  ${({ theme: { colors, fontSizes, fontFamily } }) => `
    background-color: ${colors.red.primary};
    color: ${colors.white};
    font-size: ${fontSizes[0]};
    font-family: ${fontFamily};
    padding: 1px 6px;
    border-radius: 6px;
  `}
`;

const CardLine = ({ children, ...styleProps }: any) => (
  <Paragraph fontSize={1} color="gray.60" my={0} {...styleProps}>
    {children}
  </Paragraph>
);

export const IndividualView = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { authHeader } = useAuth();

  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [isCommentsOpen, setIsCommentsOpen] = useState(false);
  const [isViewDocumentsOpen, setIsViewDocumentsOpen] = useState(false);
  const [isResetModalOpen, setIsResetModalOpen] = useState(false);
  const [isRequestingPdfDownload, setIsRequestingPdfDownload] = useState(false);
  const [isLoadingReset, setIsLoadingReset] = useState(false);
  const [isViewMore, setIsViewMore] = useState(false);
  const [showContent, setShowContent] = useState(isViewMore);

  useEffect(() => {
    if (isViewMore) {
      setShowContent(true);
    } else {
      const timer = setTimeout(() => setShowContent(false), 500); // Duration of the transition
      return () => clearTimeout(timer);
    }
  }, [isViewMore]);

  const { mutate: createIndividualsInvites, isLoading: isInvitingUser } =
    useCreateIndividualsInvites();

  const { data: individualData, isLoading } = useIndividual(params.id);

  const individual = individualData?.data;

  const individualName = isLoading
    ? "..."
    : buildName([
        individual?.first_name,
        individual?.middle_name,
        individual?.last_name,
      ]);

  const { mutate: deleteIndividual } = useDeleteIndividual();

  const { getMandateStatusId } = useMandateStatuses();
  const { data: mandateData, isLoading: isLoadingMandate } = useMandate(
    individual?.mandate
  );

  const { data: additionalDocuments, isLoading: documentsLoading } =
    useAdditionalDocuments(params.id);

  const mandate = mandateData?.data;
  const mandateReference = isLoadingMandate ? "..." : mandate?.name;

  const handleResendInvite = () => {
    createIndividualsInvites(
      {
        individual_ids: [parseInt(params.id)],
        id: individual?.mandate,
      },
      {
        onSuccess: () => {
          notify("Invite successfully sent", {
            position: "bottom-left",
            type: "success",
          });
          queryClient.invalidateQueries(["individual", params.id]);
        },
        onError: (err: any) => {
          showError(err, "A problem occurred while send your message.");
        },
      }
    );
  };

  const handleDownloadPdfClick = () => {
    if (isRequestingPdfDownload) {
      return;
    }

    setIsRequestingPdfDownload(true);
    notify("Generating PDF, this could take up to 30 seconds", {
      position: "bottom-left",
      type: "info",
      pauseOnFocusLoss: false,
      pauseOnHover: false,
      autoClose: false,
    });

    axios
      .get(`${env.verifyServiceUrl}individual/download_pdf/${params.id}`, {
        headers: {
          Authorization: authHeader,
        },
        responseType: "arraybuffer",
      })
      .then((response) => {
        clearAll();
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `report.pdf`;
        link.click();
      })
      .catch(() => {
        clearAll();
        setTimeout(() => {
          notify("There was a problem while generating the PDF report", {
            position: "bottom-left",
            type: "error",
          });
        }, 100);
      })
      .finally(() => {
        setIsRequestingPdfDownload(false);
      });
  };

  const handleResetIndividual = () => {
    setIsLoadingReset(true);
    axios
      .get(`${env.verifyServiceUrl}individual/individual/${params.id}/reset/`, {
        headers: {
          Authorization: authHeader,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          notify("Individual reset successfully.", {
            position: "bottom-left",
            type: "success",
          });
          queryClient.invalidateQueries(["mandate", mandate?.id]);
          queryClient.invalidateQueries(["individual"]);
          setIsLoadingReset(false);
          setIsResetModalOpen(false);
        } else {
          setIsLoadingReset(false);
          handleQueryError(
            "A problem occurred while resetting the individual."
          );
        }
      });
  };

  const handleConfirmDeleteIndividual = () => {
    setIsConfirmDeleteOpen(false);

    deleteIndividual(
      { id: params.id },
      {
        onSuccess: () => {
          navigate(`/applications/${mandate?.id}`);
          notify("Individual removed", {
            position: "bottom-left",
            type: "success",
          });
          queryClient.invalidateQueries(["mandate", mandate?.id]);
          // queryClient.invalidateQueries(["individual"]);
        },
        onError: (err: any) => {
          showError(err, "A problem occurred while deleting the individual.");
        },
        // onError: handleQueryError(
        //   "A problem occurred while deleting the individual."
        // ),
      }
    );
  };

  const actions = {
    countComplete: individual?.completed_actions?.length || 0,
    count: individual?.total_actions?.length || 0,
    completed: individual?.completed_actions || [],
    total: individual?.total_actions || [],
  };

  const hasInviteBeenSent = Boolean(individual?.invite_sent);
  const inviteDate = DateTime.fromISO(individual?.invite_sent).toFormat(
    "dd/MM/yyyy"
  );
  const inviteTime = DateTime.fromISO(individual?.invite_sent).toFormat(
    "h:mma"
  );

  const mandateReferenceBreadcrumbLength = mandateReference?.length;

  const canDelete =
    mandate?.status?.id !== getMandateStatusId(MandateStatus.Rejected);

  return (
    <div>
      <Topbar
        header={
          <Flex justifyContent="space-between" width="100%">
            <>
              <Breadcrumb>
                <Breadcrumb.Section linkTo={ROUTE.APPLICATIONS}>
                  Applications
                </Breadcrumb.Section>
                <Breadcrumb.Section
                  linkTo={`/applications/${individual?.mandate}`}
                >
                  {mandateReferenceBreadcrumbLength > 25
                    ? mandateReference.substring(0, 25) + "..."
                    : mandateReference}
                </Breadcrumb.Section>
                <Breadcrumb.Section>{individualName}</Breadcrumb.Section>
              </Breadcrumb>
              <Flex justifyContent="space-between">
                <Button
                  onClick={() => setIsCommentsOpen(!isCommentsOpen)}
                  mr={1}
                  variant="secondary"
                >
                  <Image
                    src={commentsIcon}
                    alt="comments"
                    height="auto"
                    width="22px"
                    mr={1}
                  />
                  Comments
                </Button>

                {individual?.can_download_pdf && (
                  <Button
                    onClick={handleDownloadPdfClick}
                    isDisabled={isRequestingPdfDownload}
                    variant="secondary"
                    color={"#2D374B"}
                    mr={1}
                  >
                    <Image
                      src={downloadIcon}
                      alt="download pdf"
                      height="auto"
                      width="22px"
                      mr={1}
                    />
                    <Paragraph fontWeight="500" fontSize={theme.fontSizes[1]}>
                      {isRequestingPdfDownload
                        ? "Downloading..."
                        : "Download PDF"}
                    </Paragraph>
                  </Button>
                )}

                {canDelete || individual?.can_download_pdf ? (
                  <IconButtonDropdown placement="bottom-end">
                    <IconButtonDropdownOption
                      onClick={() => setIsResetModalOpen(true)}
                      IconType={VscDebugRestart}
                    >
                      Reset Individual
                    </IconButtonDropdownOption>

                    {canDelete && (
                      <IconButtonDropdownOption
                        onClick={() => setIsConfirmDeleteOpen(true)}
                        IconType={FaTrash}
                        isDestructive
                      >
                        Delete Individual
                      </IconButtonDropdownOption>
                    )}
                  </IconButtonDropdown>
                ) : null}
              </Flex>
            </>
          </Flex>
        }
      ></Topbar>

      {isLoading && (
        <Flex justifyContent="center" alignItems="center" minHeight="60vh">
          <Loader />
        </Flex>
      )}

      {!isLoading && (
        <>
          <Container>
            <Flex gap="24">
              <Box width={"20%"}>
                <H3 mb={2}>Progress </H3>

                <Card mb={2}>
                  <Flex justifyContent={"space-between"} alignItems={"center"}>
                    <Heading>Uploaded documents</Heading>
                    {additionalDocuments?.data.length > 0 && (
                      <CountBox>{additionalDocuments?.data.length}</CountBox>
                    )}
                  </Flex>
                  <CardLine mb={3}>
                    View and download uploaded documents
                  </CardLine>
                  <Button
                    mt={3}
                    variant="gray"
                    paddingLeft={2}
                    backgroundColor="gray.10"
                    border="2px solid background"
                    color="blue.primary"
                    fontWeight={600}
                    onClick={() => setIsViewDocumentsOpen(!isViewDocumentsOpen)}
                  >
                    View Documents
                  </Button>
                </Card>

                <Card mb={0}>
                  <Heading>Individual reference</Heading>
                  <CardLine mb={3}>{individual?.reference}</CardLine>
                  <Heading>Invite sent to</Heading>
                  <CardLine mb={3}>{individual?.email}</CardLine>
                  <Heading>Invitation Time</Heading>
                  <CardLine>
                    {hasInviteBeenSent
                      ? `${inviteDate} at ${inviteTime}`
                      : "Never"}
                  </CardLine>

                  <Button
                    marginBottom={3}
                    mt={3}
                    variant="gray"
                    paddingLeft={2}
                    backgroundColor="gray.10"
                    border="2px solid background"
                    color="blue.primary"
                    fontWeight={600}
                    isDisabled={
                      isInvitingUser ||
                      mandate?.status?.status === MandateStatus.Completed ||
                      mandate?.status?.status === MandateStatus.Rejected ||
                      mandate?.archived
                    }
                    onClick={handleResendInvite}
                  >
                    <Image
                      src={resendInviteIcon}
                      alt="progress indicator"
                      height="auto"
                      width="22px"
                      mr={1}
                    />
                    Resend invite
                  </Button>

                  <Heading>Actions</Heading>
                  <Paragraph fontSize={1} color="gray.60" mb={0}>
                    {actions.countComplete}/{actions.count} Completed
                  </Paragraph>
                  <ProgressBar
                    progress={calculatePercentage(
                      actions.countComplete,
                      actions.count
                    )}
                    variant="green"
                    mb={2}
                    mt={1}
                  />

                  {actions.total.map(({ id, name, title }) => (
                    <ActionItem
                      key={id}
                      label={title}
                      isComplete={actions.completed.find(
                        ({ id: thisId }) => id === thisId
                      )}
                    />
                  ))}
                </Card>
              </Box>

              <Box width={"80%"}>
                <Box>
                  <Flex justifyContent={"space-between"}>
                    <H3 mb={2}>About</H3>

                    <Button
                      onClick={() => setIsViewMore(!isViewMore)}
                      variant="gray"
                      minWidth={122}
                      height={30}
                    >
                      {isViewMore ? "View less" : "View more"}
                    </Button>
                  </Flex>

                  <Card mb={2}>
                    <Flex gap="24">
                      <Box
                        flex={1}
                        borderRight="1px solid"
                        borderColor="gray.20"
                      >
                        <Heading mb={2}>Invite Details</Heading>

                        <Flex gap="24">
                          <Box flex={1}>
                            <CardLine>First name</CardLine>
                            <Heading>
                              {individual?.first_name
                                ? individual?.first_name
                                : "-"}
                            </Heading>
                          </Box>
                          <Box flex={1}>
                            <CardLine>Middle name</CardLine>
                            <Heading>
                              {individual?.middle_name
                                ? individual?.middle_name
                                : "-"}
                            </Heading>
                          </Box>
                          <Box flex={1}>
                            <CardLine>Last name</CardLine>
                            <Heading>
                              {individual?.last_name
                                ? individual?.last_name
                                : "-"}
                            </Heading>
                          </Box>
                        </Flex>
                        <Flex gap="24">
                          <Box flex={1}>
                            <CardLine>Date of birth</CardLine>
                            <Heading>
                              {individual?.date_of_birth
                                ? DateTime.fromISO(
                                    individual?.date_of_birth
                                  ).toFormat("dd/MM/yyyy")
                                : "-"}
                            </Heading>
                          </Box>
                          <Box flex={1}>
                            <CardLine>Gender</CardLine>
                            <Heading>
                              {individual?.gender
                                ? capitalise(individual?.gender)
                                : "-"}
                            </Heading>
                          </Box>
                          <Box flex={1}>
                            <CardLine>Title</CardLine>
                            <Heading>
                              {individual?.title ? individual?.title : "-"}
                            </Heading>
                          </Box>
                        </Flex>
                      </Box>

                      <Box flex={1}>
                        <Heading mb={2}>Personal Details</Heading>

                        <Flex gap="24">
                          <Box flex={1}>
                            <CardLine>First name</CardLine>
                            <Heading>
                              {individual?.personal_detail?.first_name
                                ? individual?.personal_detail?.first_name
                                : "-"}
                            </Heading>
                          </Box>
                          <Box flex={1}>
                            <CardLine>Middle name</CardLine>
                            <Heading>
                              {individual?.personal_detail?.middle_name
                                ? individual?.personal_detail?.middle_name
                                : "-"}
                            </Heading>
                          </Box>
                          <Box flex={1}>
                            <CardLine>Last name</CardLine>
                            <Heading>
                              {individual?.personal_detail?.last_name
                                ? individual?.personal_detail?.last_name
                                : "-"}
                            </Heading>
                          </Box>
                        </Flex>
                        <Flex gap="24">
                          <Box flex={1}>
                            <CardLine>Date of birth</CardLine>
                            <Heading>
                              {individual?.personal_detail?.date_of_birth
                                ? DateTime.fromISO(
                                    individual?.personal_detail?.date_of_birth
                                  ).toFormat("dd/MM/yyyy")
                                : "-"}
                            </Heading>
                          </Box>
                          <Box flex={1}>
                            <CardLine>Gender</CardLine>
                            <Heading>
                              {individual?.personal_detail?.gender
                                ? capitalise(
                                    individual?.personal_detail?.gender
                                  )
                                : "-"}
                            </Heading>
                          </Box>
                          <Box flex={1}></Box>
                        </Flex>
                      </Box>
                    </Flex>
                  </Card>

                  <ViewBox className={`${isViewMore ? "expanded" : ""}`}>
                    {showContent && (
                      <Card mb={2}>
                        <Flex gap="24">
                          <Box flex={0.8}>
                            <Box mb={2}>
                              <Heading>Address</Heading>
                              {individual?.address ? (
                                <>
                                  <CardLine>
                                    {individual?.address
                                      ?.flat_appartment_subbuilding && (
                                      <span>
                                        {
                                          individual?.address
                                            ?.flat_appartment_subbuilding
                                        }
                                        &nbsp;
                                      </span>
                                    )}
                                    {individual?.address?.building_number && (
                                      <span>
                                        {individual?.address?.building_number}
                                        &nbsp;
                                      </span>
                                    )}
                                    {individual?.address?.building_name && (
                                      <span>
                                        {individual?.address?.building_name}
                                        &nbsp;
                                      </span>
                                    )}
                                    {individual?.address?.road_street}
                                  </CardLine>
                                  <CardLine>
                                    {individual?.address?.town_city && (
                                      <span>
                                        {individual?.address?.town_city}
                                        &nbsp;
                                      </span>
                                    )}
                                    {individual?.address?.district}
                                  </CardLine>
                                  <CardLine>
                                    {individual?.address?.post_zip_code}
                                  </CardLine>

                                  <Flex
                                    gap="8"
                                    alignItems={"center"}
                                    mb={1}
                                    fontSize={1}
                                    color="gray.60"
                                    fontFamily={"'Inter', sans-serif"}
                                  >
                                    {individual?.address?.country_flag && (
                                      <Box
                                        backgroundColor={"gray.10"}
                                        borderRadius={"50%"}
                                        p={"1"}
                                      >
                                        <img
                                          src={`data:image/png;base64,${individual?.address?.country_flag}`}
                                          alt="iamge"
                                        />
                                      </Box>
                                    )}
                                    {individual?.address?.country_flag && (
                                      <span>
                                        &nbsp;
                                        {individual?.address?.country_name}
                                      </span>
                                    )}
                                  </Flex>
                                </>
                              ) : (
                                "-"
                              )}
                            </Box>

                            <Box mb={2}>
                              {individual?.former_name &&
                                individual?.former_name?.length > 0 && (
                                  <>
                                    <Heading>Former Name(s)</Heading>
                                    {individual?.former_name?.map(
                                      (
                                        {
                                          id,
                                          first_name,
                                          last_name,
                                          middle_name,
                                          change_date,
                                        },
                                        i
                                      ) => (
                                        <Box key={id}>
                                          <CardLine>
                                            {first_name} {middle_name}{" "}
                                            {last_name}
                                          </CardLine>
                                          {change_date && (
                                            <CardLine color="gray.40">
                                              Changed{" "}
                                              {change_date
                                                ? DateTime.fromISO(
                                                    change_date
                                                  ).toFormat("dd/MM/yyyy")
                                                : "-"}
                                            </CardLine>
                                          )}
                                        </Box>
                                      )
                                    )}
                                  </>
                                )}
                            </Box>

                            {individual?.bank_account_detail && (
                              <Box flex={1}>
                                <Heading>Bank Account Details</Heading>
                                {individual?.bank_account_detail ? (
                                  <>
                                    <CardLine mt={1}>Name on account</CardLine>
                                    <CardLine color="gray.40">
                                      {individual?.bank_account_detail
                                        ?.account_name && (
                                        <span>
                                          {
                                            individual?.bank_account_detail
                                              ?.account_name
                                          }
                                          &nbsp;
                                        </span>
                                      )}
                                    </CardLine>
                                    <CardLine mt={1}>Account number</CardLine>
                                    <CardLine color="gray.40">
                                      {individual?.bank_account_detail
                                        ?.account_number && (
                                        <span>
                                          {
                                            individual?.bank_account_detail
                                              ?.account_number
                                          }
                                          &nbsp;
                                        </span>
                                      )}
                                    </CardLine>
                                    <CardLine mt={1}>Sort code</CardLine>
                                    <CardLine color="gray.40">
                                      {individual?.bank_account_detail
                                        ?.sort_code && (
                                        <span>
                                          {
                                            individual?.bank_account_detail
                                              ?.sort_code
                                          }
                                          &nbsp;
                                        </span>
                                      )}
                                    </CardLine>
                                  </>
                                ) : (
                                  "-"
                                )}
                              </Box>
                            )}
                          </Box>
                          <Box flex={0.8}>
                            <Heading>Previous Addresses</Heading>
                            {individual?.previous_address &&
                            individual?.previous_address.length > 0 ? (
                              <>
                                {individual?.previous_address?.map(
                                  (prevAddress, i) => (
                                    <>
                                      <Heading style={{ fontSize: 14 }}>
                                        Previous Address {i + 1}
                                      </Heading>
                                      <Box mb={2}>
                                        <CardLine>
                                          {prevAddress?.flat_appartment_subbuilding && (
                                            <span>
                                              {
                                                prevAddress?.flat_appartment_subbuilding
                                              }
                                              &nbsp;
                                            </span>
                                          )}
                                          {prevAddress?.building_number && (
                                            <span>
                                              {prevAddress?.building_number}
                                              &nbsp;
                                            </span>
                                          )}
                                          {prevAddress?.building_name && (
                                            <span>
                                              {prevAddress?.building_name}
                                              &nbsp;
                                            </span>
                                          )}
                                          {prevAddress?.road_street}
                                        </CardLine>

                                        <CardLine>
                                          {prevAddress?.town_city && (
                                            <span>
                                              {prevAddress?.town_city}
                                              &nbsp;
                                            </span>
                                          )}
                                          {prevAddress?.district},{" "}
                                          {prevAddress?.post_zip_code}
                                        </CardLine>

                                        <CardLine>
                                          {DateTime.fromISO(
                                            prevAddress?.date_from
                                          ).toFormat("dd/MM/yyyy")}{" "}
                                          -{" "}
                                          {DateTime.fromISO(
                                            prevAddress?.date_to
                                          ).toFormat("dd/MM/yyyy")}
                                        </CardLine>

                                        <Flex
                                          gap="8"
                                          alignItems={"center"}
                                          mb={1}
                                          fontSize={1}
                                          color="gray.60"
                                          fontFamily={"'Inter', sans-serif"}
                                        >
                                          {prevAddress?.country_flag && (
                                            <Box
                                              backgroundColor={"gray.10"}
                                              borderRadius={"50%"}
                                              p={"1"}
                                            >
                                              <img
                                                src={`data:image/png;base64,${prevAddress?.country_flag}`}
                                                alt="iamge"
                                              />
                                            </Box>
                                          )}
                                          {prevAddress?.country_flag && (
                                            <span>
                                              &nbsp;
                                              {prevAddress?.country_name}
                                            </span>
                                          )}
                                        </Flex>
                                      </Box>
                                    </>
                                  )
                                )}
                              </>
                            ) : (
                              "-"
                            )}
                          </Box>

                          <Box flex={1.4}>
                            {individual?.nationality &&
                              individual?.nationality?.length > 0 && (
                                <Box mb={2}>
                                  <Heading>Nationality</Heading>

                                  {individual?.nationality?.map(
                                    ({ icon, nationality }, i) => (
                                      <Flex
                                        key={i}
                                        gap="16"
                                        mb={1}
                                        alignItems={"center"}
                                        fontSize={1}
                                        color="gray.60"
                                        fontFamily={"'Inter', sans-serif"}
                                      >
                                        {icon && (
                                          <Box
                                            backgroundColor={"gray.10"}
                                            borderRadius={"50%"}
                                            p={"1"}
                                          >
                                            <img
                                              src={`data:image/png;base64,${icon}`}
                                              alt="iamge"
                                            />
                                          </Box>
                                        )}
                                        {nationality && (
                                          <>
                                            &nbsp;
                                            {nationality}
                                          </>
                                        )}
                                      </Flex>
                                    )
                                  )}
                                </Box>
                              )}

                            {individual?.place_of_birth && (
                              <Box mb={2}>
                                <Heading>Place of Birth</Heading>
                                <CardLine>
                                  {individual?.place_of_birth && (
                                    <span>{individual?.place_of_birth}</span>
                                  )}
                                </CardLine>
                              </Box>
                            )}
                            {individual?.geo_location?.lat != null &&
                              individual?.geo_location?.long != null &&
                              individual?.geo_location
                                ?.location_service_permitted && (
                                <>
                                  <Heading>Geolocation</Heading>
                                  <CardLine>
                                    Latitude: &nbsp;
                                    <span>{individual?.geo_location?.lat}</span>
                                  </CardLine>
                                  <CardLine>
                                    Longitude: &nbsp;
                                    <span>
                                      {individual?.geo_location?.long}
                                    </span>
                                  </CardLine>
                                  <br />
                                  <Box height="170px">
                                    <Geolocation
                                      lat={individual?.geo_location?.lat}
                                      long={individual?.geo_location?.long}
                                    />
                                  </Box>
                                </>
                              )}
                          </Box>
                        </Flex>
                      </Card>
                    )}
                  </ViewBox>
                </Box>

                <H3 mb={2}>Checks</H3>

                <Flex gap="40" flexWrap={"wrap"}>
                  {Object.entries(individual?.checks).map(
                    ([key, check], index) => (
                      <Box flex={1} key={index}>
                        <Flex justifyContent="space-between">
                          <H3 mb="0px">
                            <Flex alignItems="center" gap="8">
                              <Image
                                src={checksIcons[key]}
                                alt="icon"
                                height="20px"
                                width="auto"
                                mr={1}
                              />
                              {capitalise(key)}
                            </Flex>
                          </H3>
                          <Heading color="gray.50">{check?.length}</Heading>
                        </Flex>

                        <ChecksBar variant={checksColor[key]}></ChecksBar>

                        {check?.map(
                          ({ id, name, status, title, updated_at }, i) => (
                            <CheckSection
                              key={i}
                              title={title}
                              status={status}
                              id={id}
                              name={name}
                              date={updated_at}
                              useSlider={true}
                            />
                          )
                        )}
                      </Box>
                    )
                  )}
                </Flex>
              </Box>
            </Flex>
          </Container>
          <Comments
            isOpen={isCommentsOpen}
            onRequestClose={() => setIsCommentsOpen(false)}
            referenceId={individual?.id}
            commentType="Individual"
          />
          <AdditionalDocuments
            isOpen={isViewDocumentsOpen}
            onRequestClose={() => setIsViewDocumentsOpen(false)}
            documents={additionalDocuments}
            documentsLoading={documentsLoading}
          />
          <Modal
            isOpen={isConfirmDeleteOpen}
            onClose={() => setIsConfirmDeleteOpen(false)}
            onModalConfirmClick={handleConfirmDeleteIndividual}
            title="Do you want to delete individual?"
            content="This is an irreversible action, if you delete it you will not be able to undo the change. "
            hasSecondaryButton
            secondaryButtonText="Cancel"
            width="450px"
            primaryButtonText="Delete"
            primaryButtonVariant="danger"
          />
          <Modal
            isClosingDisabled={isLoadingReset}
            isLoadingAction={isLoadingReset}
            loadingActionText="Resetting..."
            isOpen={isResetModalOpen}
            onClose={() => setIsResetModalOpen(false)}
            title="Do you want to reset individual?"
            content="This is an irreversible action, if you reset the individual you will not be able to undo the change."
            onModalConfirmClick={() => handleResetIndividual()}
            secondaryButtonText="Cancel"
            primaryButtonText="Reset"
            primaryButtonVariant="danger"
            hasSecondaryButton
            width="450px"
          />
        </>
      )}
    </div>
  );
};
