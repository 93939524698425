export const TOP_LEVEL_ROUTES = {
  ROOT: "/",
  SIGN_UP: "/sign-up",
  CREATE_PASSWORD: "/create-password",
  RESET_PASSWORD: "/forgot-password",
  RESET_PASSWORD_CONFIRMATION: "/user/password",
  LOGIN: "/login",
  LOGOUT: "/logout",
  NOT_FOUND: "*",
  VERIFY: "/get-verified",
  ADD_COMPANY: "/add-company",
  APPLICATIONS: "/applications",
  CHECKS: "/checks",

  APPLICATION: "/applications/:id",
  APPLICATION_NEW: "/applications/new",
  APPLICATION_INDIVIDUALS: "/applications/:id/individuals",
  APPLICATION_INDIVIDUALS_NEW: "/applications/:id/individuals/new",

  CHECKS_NEW: "/checks/new",
  CHECK_INDIVIDUALS: "/checks/:id/individuals",
  CHECK_INDIVIDUALS_NEW: "/checks/individuals/new",
  CHECK_ADDRESS_NEW: "/checks/:id/address/:individualId",
  RUN_CHECKS: "/checks/:id/run-checks/:individualId",
  CHECKS_INDIVIDUAL: "/checks/:mandate/individuals/:id",

  SETTINGS: "/settings",
  SETTINGS_USERS: "/settings/users",
  SETTINGS_APPLICATION_TYPE: "/settings/application-types/:id",
  SETTINGS_APPLICATION_TYPE_NEW: "/settings/application-types/new",
  SETTINGS_APPLICATION_TYPES: "/settings/application-types",
  SETTINGS_SOF_RISK_SCORING: "/settings/sof-risk-scoring",
  SETTINGS_ADDITIONAL_DOCUMENTS_REQUESTS: "/settings/additional-documents-requests",
  SETTINGS_PEP_SANCTION_SCREENING: "/settings/pep-sanctions-screening",
  SETTINGS_BILLING_DETAILS: "/settings/billing-details",
  SETTINGS_DPO_DETAILS: "/settings/dpo-details",
  SETTINGS_BRANDING: "/settings/branding",
  PROFILE: "/profile",
  ACCOUNT_PENDING: `/account-pending`,

  HELP_AND_SUPPORT: "/help-and-support",
};

const NESTED_ROUTES = {
  VERIFY: `${TOP_LEVEL_ROUTES.SIGN_UP}/get-verified`,
  ADD_COMPANY: `${TOP_LEVEL_ROUTES.SIGN_UP}/add-company`,
  ADD_COMPANY_ADDRESS: `${TOP_LEVEL_ROUTES.SIGN_UP}/add-company-address`,
  ADD_BILLING_INFORMATION: `${TOP_LEVEL_ROUTES.SIGN_UP}/add-billing-information`,
  ADD_BILLING_ADDRESS: `${TOP_LEVEL_ROUTES.SIGN_UP}/add-billing-address`,
  ADD_PRIMARY_PURPOSE: `${TOP_LEVEL_ROUTES.SIGN_UP}/add-primary-purpose-details`,
  ADD_DPO: `${TOP_LEVEL_ROUTES.SIGN_UP}/DPO-details`,
};

const APPLICATION = {
  INDIVIDUAL: `${TOP_LEVEL_ROUTES.APPLICATION}/individuals/:id`,
};

export const ROUTE = {
  ...TOP_LEVEL_ROUTES,
  ...NESTED_ROUTES,
  ...APPLICATION,
};
