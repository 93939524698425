import { Box } from "../../components/Box";
import { Flex } from "../../components/Flex";
import { Loader } from "../../components/Loader";
import { Paragraph } from "../../components/Paragraph";
import { Slider } from "../../components/Slider";
import { CheckStatusEnum } from "../../context/MandateStatusContext";
import { useIndividualCheck } from "../../data/useIndividualCheck";
import {
  CheckContainer,
  CheckHeading,
  CheckTitle,
  Section,
} from "./CheckStyledComponents";
import { Geolocation } from "../../components/Geolocation";
import { IndividualChecksMap } from "./helper";
import { ManualAcceptReject } from "./ManualAcceptReject";
import { CheckResults } from "./CheckResults";
import { CheckFields } from "./CheckFields";
import { ApprovalComment } from "./approvalComment";

export const GeoLocationCheck = (props) => {
  const { isOpen, onRequestClose, id, useSlider = true } = props;

  const { data: individualCheckData, isLoading: isLoadingIndividualCheck } =
    useIndividualCheck(id);

  const instance = individualCheckData?.data?.instance;

  const content = (
    <CheckContainer>
      <Flex justifyContent="space-between">
        <CheckTitle>{IndividualChecksMap.geo_location_check.title}</CheckTitle>
        {individualCheckData?.data?.status?.status ===
          CheckStatusEnum.Review && (
          <ManualAcceptReject
            id={id}
            title={IndividualChecksMap.geo_location_check.title}
          ></ManualAcceptReject>
        )}
      </Flex>

      {individualCheckData?.data?.status?.status && (
        <CheckResults
          status={individualCheckData?.data?.status?.status}
        ></CheckResults>
      )}

      {instance && instance?.approval_comment && (
        <ApprovalComment
          data={instance?.approval_comment}
          status={individualCheckData?.data?.status?.status}
        ></ApprovalComment>
      )}

      <CheckFields
        individual_checkfield_result={
          individualCheckData?.data?.individual_checkfield_result
        }
      ></CheckFields>

      {instance && (
        <Section>
          <Flex>
            <Box flex={0.5}>
              <CheckHeading>Status:</CheckHeading>
              <Paragraph mt={1}>{instance?.status}</Paragraph>

              <CheckHeading>Country of address:</CheckHeading>
              <Paragraph mt={1}>{instance?.country}</Paragraph>

              <CheckHeading>Location:</CheckHeading>
              <Paragraph mt={1}>{instance?.location}</Paragraph>

              <CheckHeading>Latitude:</CheckHeading>
              <Paragraph mt={1}>{instance?.latitude}</Paragraph>
              <CheckHeading>Longitude:</CheckHeading>
              <Paragraph mt={1}>{instance?.longitude}</Paragraph>
            </Box>
            <Box flex={1.5}>
              {instance?.longitude && instance?.latitude && (
                <Box height="50vh">
                  <Geolocation
                    lat={Number(instance?.latitude)}
                    long={Number(instance?.longitude)}
                  />
                </Box>
              )}
            </Box>
          </Flex>
        </Section>
      )}
    </CheckContainer>
  );
  return (
    <>
      {isLoadingIndividualCheck ? (
        <Flex justifyContent="center" alignItems="center" minHeight="60vh">
          <Loader />
        </Flex>
      ) : useSlider ? (
        <Slider
          isOpen={isOpen}
          onRequestClose={() => onRequestClose(false)}
          width="70%"
        >
          {content}
        </Slider>
      ) : (
        content
      )}
    </>
  );
};
