import { Route, Routes, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import Applications from "./pages/Applications";
import ResetPassword from "./pages/ResetPassword";
import { ROUTE } from "./routes";
import "./App.css";
import AuthenticatedRoute from "./pages/AuthenticatedRoute";
import NotFound from "./pages/NotFound";
import Settings from "./pages/Settings";
import "react-toastify/dist/ReactToastify.css";
import "./customToastify.css";
import SettingsCompanyDetails from "./pages/Settings/SettingsCompanyDetails";
import SettingsUsers from "./pages/Settings/SettingsUsers";
import SettingsApplicationTypeView from "./pages/Settings/SettingsApplicationTypeView";
import SettingsApplicationTypeNew from "./pages/Settings/SettingsApplicationTypeNew";
import SettingsApplicationTypes from "./pages/Settings/SettingsApplicationTypes";
import SettingsBillingDetails from "./pages/Settings/SettingsBillingDetails";
import { Profile } from "./pages/Profile";
import { NewApplication } from "./pages/NewApplication";
import { ApplicationView } from "./pages/ApplicationView";
import AccountPending from "./pages/AccountPending";
import ResetPasswordConfirmation from "./pages/ResetPasswordConfirmation";
import { IndividualView } from "./pages/IndividualView";
import { ApplicationIndividuals } from "./pages/ApplicationIndividuals";
import { AddApplicationIndividual } from "./pages/ApplicationIndividuals/AddApplicationIndividual";
import Logout from "./pages/Logout";
import CacheBuster from "react-cache-buster";
import packageInfo from "../package.json";
import CreatePassword from "./pages/CreatePassword";
import SettingsDPO from "./pages/Settings/SettingsDPO";
import SettingsPEPSanctions from "./pages/Settings/SettingsPEP&Sanctions";
import { HelpSupport } from "./pages/HelpSupport";
import SettingsBranding from "./pages/Settings/SettingsBranding";
import SettingsSOFRiskScoring from "./pages/Settings/SettingsSOFRiskScoring";
import Checks from "./pages/Checks";
import { AddCheckIndividual } from "./pages/ChecksIndividuals/AddCheckIndividual";
import { IndividualChecksView } from "./pages/IndividualChecksView";
import { RunChecks } from "./pages/ChecksIndividuals/RunChecks";
import { NewCheck } from "./pages/ChecksIndividuals/NewCheck";
import { AddAddress } from "./pages/ChecksIndividuals/AddAddress";
import SettingsAdditionalDocumentsRequests from "./pages/Settings/SettingsAdditionalDocumentsRequests";

function App() {
  const isProduction =
    process.env.NODE_ENV === "production" ||
    process.env.NODE_ENV === "development" ||
    process.env.NODE_ENV === "test";
  return (
    <>
      <CacheBuster
        currentVersion={packageInfo.version}
        isEnabled={isProduction} //If false, the library is disabled.
      >
        <Routes>
          <Route path="/" element={<Navigate to={ROUTE.LOGIN} />} />
          <Route path={ROUTE.LOGIN} element={<Login />} />
          <Route path={ROUTE.LOGOUT} element={<Logout />} />
          <Route path={ROUTE.SIGN_UP} element={<SignUp />}>
            <Route path={ROUTE.VERIFY} element={<SignUp />} />
            <Route path={ROUTE.ADD_COMPANY} element={<SignUp />} />
            <Route path={ROUTE.ADD_COMPANY_ADDRESS} element={<SignUp />} />
            <Route path={ROUTE.ADD_BILLING_INFORMATION} element={<SignUp />} />
            <Route path={ROUTE.ADD_BILLING_ADDRESS} element={<SignUp />} />
            <Route path={ROUTE.ADD_PRIMARY_PURPOSE} element={<SignUp />} />
            <Route path={ROUTE.ADD_DPO} element={<SignUp />} />
          </Route>
          <Route path={ROUTE.CREATE_PASSWORD} element={<CreatePassword />} />
          <Route path={ROUTE.RESET_PASSWORD} element={<ResetPassword />} />
          <Route
            path={ROUTE.RESET_PASSWORD_CONFIRMATION}
            element={<ResetPasswordConfirmation />}
          />
          <Route path={ROUTE.ACCOUNT_PENDING} element={<AccountPending />} />
          <Route
            path={ROUTE.INDIVIDUAL}
            element={
              <AuthenticatedRoute>
                <IndividualView />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={ROUTE.APPLICATIONS}
            element={
              <AuthenticatedRoute>
                <Applications />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={ROUTE.APPLICATION_NEW}
            element={
              <AuthenticatedRoute>
                <NewApplication />
              </AuthenticatedRoute>
            }
          />

          <Route
            path={ROUTE.CHECKS}
            element={
              <AuthenticatedRoute>
                <Checks />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={ROUTE.CHECKS_INDIVIDUAL}
            element={
              <AuthenticatedRoute>
                <IndividualChecksView />
              </AuthenticatedRoute>
            }
          />

          <Route
            path={ROUTE.CHECKS_NEW}
            element={
              <AuthenticatedRoute>
                <NewCheck />
              </AuthenticatedRoute>
            }
          />

          <Route
            path={ROUTE.CHECK_INDIVIDUALS_NEW}
            element={
              <AuthenticatedRoute>
                <AddCheckIndividual />
              </AuthenticatedRoute>
            }
          />

          <Route
            path={ROUTE.CHECK_ADDRESS_NEW}
            element={
              <AuthenticatedRoute>
                <AddAddress />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={ROUTE.RUN_CHECKS}
            element={
              <AuthenticatedRoute>
                <RunChecks />
              </AuthenticatedRoute>
            }
          />

          <Route
            path={ROUTE.APPLICATION}
            element={
              <AuthenticatedRoute>
                <ApplicationView />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={ROUTE.APPLICATION_INDIVIDUALS}
            element={
              <AuthenticatedRoute>
                <ApplicationIndividuals />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={ROUTE.APPLICATION_INDIVIDUALS_NEW}
            element={
              <AuthenticatedRoute>
                <AddApplicationIndividual />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={ROUTE.SETTINGS}
            element={
              <AuthenticatedRoute>
                <Settings />
              </AuthenticatedRoute>
            }
          >
            <Route index element={<SettingsCompanyDetails />} />
            <Route
              path={ROUTE.SETTINGS_BRANDING}
              element={<SettingsBranding />}
            />
            <Route
              path={ROUTE.SETTINGS_BILLING_DETAILS}
              element={<SettingsBillingDetails />}
            />
            <Route path={ROUTE.SETTINGS_USERS} element={<SettingsUsers />} />
            <Route
              path={ROUTE.SETTINGS_DPO_DETAILS}
              element={<SettingsDPO />}
            />

            <Route
              path={ROUTE.SETTINGS_APPLICATION_TYPE}
              element={<SettingsApplicationTypeView />}
            />
            <Route
              path={ROUTE.SETTINGS_APPLICATION_TYPE_NEW}
              element={<SettingsApplicationTypeNew />}
            />
            <Route
              path={ROUTE.SETTINGS_APPLICATION_TYPES}
              element={<SettingsApplicationTypes />}
            />
            <Route
              path={ROUTE.SETTINGS_SOF_RISK_SCORING}
              element={<SettingsSOFRiskScoring />}
            />
            <Route
              path={ROUTE.SETTINGS_ADDITIONAL_DOCUMENTS_REQUESTS}
              element={<SettingsAdditionalDocumentsRequests />}
            />
            <Route
              path={ROUTE.SETTINGS_PEP_SANCTION_SCREENING}
              element={<SettingsPEPSanctions />}
            />
          </Route>
          <Route
            path={ROUTE.PROFILE}
            element={
              <AuthenticatedRoute>
                <Profile />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={ROUTE.HELP_AND_SUPPORT}
            element={
              <AuthenticatedRoute>
                <HelpSupport />
              </AuthenticatedRoute>
            }
          />
          {/* 👇️ only match this when no other routes match */}
          <Route path={ROUTE.NOT_FOUND} element={<NotFound />} />
        </Routes>
      </CacheBuster>
    </>
  );
}

export default App;
