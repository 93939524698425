export const env = {
  verifyServiceUrl: process.env.REACT_APP_VERIFY_SERVICE_URL as string,
  cookieExpiryMs: Number.parseInt(
    process.env.REACT_APP_COOKIE_EXPIRY_MS as string
  ),
  cookieName: process.env.REACT_APP_COOKIE_NAME as string,
  verifyTermsAndConditionsUrl: process.env
    .REACT_APP_VERIFY_TERMS_AND_CONDITIONS_URL as string,
  verifyPrivacyPolicyUrl: process.env
    .REACT_APP_VERIFY_PRIVACY_POLICY_URL as string,
  isFilterApplicationsFeatureOn:
    process.env.REACT_APP_IS_FILTER_APPLICATIONS_FEATURE_ON === "true",
  isSearchApplicationsFeatureOn:
    process.env.REACT_APP_IS_SEARCH_APPLICATIONS_FEATURE_ON === "true",
  isSearchChecksFeatureOn:
    process.env.REACT_APP_IS_SEARCH_CHECKS_FEATURE_ON === "true",
  verifyGoogleMapKey: process.env.REACT_APP_GOOGLEMAP_KEY as string,
};
