import { forwardRef } from "react";
import styled from "styled-components";
import {
  layout,
  LayoutProps,
  space,
  SpaceProps,
  color,
  ColorProps,
  position,
  PositionProps,
  border,
  BordersProps,
  typography,
  TypographyProps,
} from "styled-system";
import { Icon } from "../Icon";

const sizes: any = {
  regular: "40px",
  large: "48px",
};

const ButtonBase = styled.button<any>`
  ${({
    baseSize,
    variant,
    disabled,
    theme: { radii, colors, space, fontSizes },
  }) => `
        height: ${sizes[baseSize]};
        padding-left: ${space[3]};
        padding-right: ${space[3]};
        box-sizing: border-box;
        border-radius: ${radii[0]};
        border: none;
        color: ${colors.white};
        font-size: ${fontSizes[1]};
        font-family: "Inter", sans-serif;
        cursor: pointer;
        transition: background 0.1s ease; 
        display: flex;
        align-items: center;
        justify-content: center;

        ${
          variant === "primary"
            ? `
            background: ${colors.blue[disabled ? 20 : "primary"]};

            &:hover, &:active {
                background: ${colors.blue[disabled ? 20 : "primaryEmphasis"]}
            }
        `
            : ""
        }

        ${
          variant === "danger"
            ? `
        background: ${colors.red[disabled ? 20 : "primary"]};

        &:hover, &:active {
            background: ${colors.red[disabled ? 20 : "primaryEmphasis"]}
        }
         `
            : ""
        }

        ${
          variant === "secondary"
            ? `
            background: ${colors.white};
            color: ${colors.gray[50]};
            border: 2px solid ${colors.background};

            &:hover, &:active {
                background: ${colors.background};
                border: 2px solid ${colors.gray[40]};
            }
        `
            : ""
        }

        ${
          variant === "gray"
            ? `
            background: ${colors.background};
            color: ${colors.gray[50]};
            border: 2px solid ${colors.gray[40]};

            &:hover, &:active {
                background: ${colors.background};
                border: 2px solid ${colors.gray[40]};
            }
        `
            : ""
        }

        ${
          variant === "text"
            ? `
            background: transparent;
            color: ${colors.blue.primary};

            &:hover, &:active {
                background: ${colors.background}
            }
        `
            : ""
        }

        ${
          variant === "blueText"
            ? `
            background: transparent;
            color: ${colors.blue.primary};

            
        `
            : ""
        }

        ${
          variant === "grayText"
            ? `
            background: transparent;
            color: ${colors.gray[60]};

            
        `
            : ""
        }

        ${
          variant === "danger-text"
            ? `
            background: transparent;
            color: ${colors.red.primary};
            font-weight: bold;

            &:hover, &:active {
                background: ${colors.background}
            }
        `
            : ""
        }

        ${
          disabled
            ? `
            cursor: not-allowed;
        `
            : ""
        }
    `}

  ${typography}
  ${space}
  ${position}
  ${layout}
  ${color}
  ${border}
`;

interface ButtonProps
  extends SpaceProps,
    TypographyProps,
    LayoutProps,
    ColorProps,
    PositionProps,
    BordersProps {
  isDisabled?: boolean;
  variant?:
    | "primary"
    | "secondary"
    | "text"
    | "danger"
    | "gray"
    | "danger-text"
    | "blueText"
    | "grayText";
  Icon?: any; // Instance of react-icon
  iconPosition?: "left" | "right";
  iconSize?: string;
  size?: "small" | "regular" | "large";
  children: any;
  onClick?: () => void;
  type?: "button" | "submit" | "reset" | undefined;
  iconColor?: string;
}

export const Button = forwardRef((props: ButtonProps, ref) => {
  const {
    Icon: PropIcon,
    iconPosition = "left",
    iconSize,
    iconColor,
    children,
    isDisabled,
    onClick,
    size = "regular",
    variant = "primary",
    type,
    ...styleProps
  } = props;

  const handleClick = () => {
    if (!isDisabled && onClick) {
      onClick();
    }
  };

  return (
    <ButtonBase
      ref={ref}
      variant={variant}
      baseSize={size}
      disabled={isDisabled}
      onClick={handleClick}
      type={type ? type : "button"}
      {...styleProps}
    >
      {iconPosition === "right" && children}
      {PropIcon && (
        <Icon
          Type={PropIcon}
          color={iconColor || (variant === "primary" ? "white" : "gray.50")}
          size={iconSize || "22px"}
          mr={iconPosition === "left" ? 1 : "0px"}
          ml={iconPosition === "right" ? 1 : "0px"}
        />
      )}
      {iconPosition === "left" && children}
    </ButtonBase>
  );
});
