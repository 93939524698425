import { Box } from '../Box';
import "./tooltip.css";

export const Tooltip = ({ children, content }) => {
  return (
    <Box className="tooltip">
      {children}
      <span className="tooltip-text">{content}</span>
    </Box>
  );
};