import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ROUTE } from "../../routes";
import { Box } from "../../components/Box";
import { Paragraph } from "../../components/Paragraph";
import { Flex } from "../../components/Flex";
import styled from "styled-components";
import { Image } from "../../components/Image";
import open from "../../assets/settings/expansion-open.svg";
import close from "../../assets/settings/expansion-close.svg";
import general from "../../assets/settings/setting.svg";
import application from "../../assets/settings/application.svg";
import { useDirtyForm } from "./DirtyFormContext";
import { Modal } from "../../components/Modal";

const StyledUl = styled.ul`
  ${({ theme: { fontSizes, fontFamily } }) => `
      list-style-type: none;
      margin-top: auto;
      margin: 0;
      list-style: none;
      font-family: ${fontFamily};
      font-size: ${fontSizes[1]};
      list-style-type: none;
      padding: 0;
    `}
`;

export const StyledLi = styled.li`
  ${({ theme: { space } }) => `
        user-select: none;
        margin-left: ${space[2]};
        display: flex;
        align-items: center;  
  `}
`;

export const StyledLink = styled(Link)`
  ${({ theme: { colors, radii, space } }) => `
        text-decoration: none;
        color: ${colors.gray[60]};
        transition: color .1s ease;
        padding: ${space[1]} ;

        &:hover {
          background-color: ${colors.gray[10]};
          border-radius: ${radii[0]};
        }

        &.active {
          color: ${colors.blue.primary};
          font-weight: bold;
        }
    `}
`;

export const StyleParagraph = styled(Paragraph)`
  ${({ theme: { space, fontSizes } }) => `
      margin-top: ${space[1]};
      margin-bottom: ${space[1]};      
      font-weight: 800;
      font-size: ${fontSizes[2]};
      align-items: center;
      display: flex;
  `}
`;
export const StyleDIV = styled(Flex)`
  ${({ theme: { colors, space, radii } }) => `
      margin-bottom: ${space[0]};
      padding-left: ${space[0]};
      cursor: pointer;
      justify-content: space-between;
      align-items: center;
      margin-top: ${space[0]};

      &.active {
        color: ${colors.blue.primary};
        background-color: ${colors.gray[10]};
        border-radius: ${radii[0]};        
      }
  `}
`;

const LeftMenu = () => {
  const [generalOpen, setGeneralOpen] = useState(true);
  const [applicationOpen, setApplicationOpen] = useState(false);
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [redirectRoute, setRedirectRoute] = useState(null);
  const { isFormDirty, setFormDirty, formRef } = useDirtyForm(); // Access the dirty form state from context
  const navigate = useNavigate();

  useEffect(() => {
    if (
      location.pathname === ROUTE.SETTINGS_PEP_SANCTION_SCREENING ||
      location.pathname === ROUTE.SETTINGS_SOF_RISK_SCORING ||
      location.pathname.includes(ROUTE.SETTINGS_APPLICATION_TYPES)||
      location.pathname.includes(ROUTE.SETTINGS_ADDITIONAL_DOCUMENTS_REQUESTS)
    ) {
      setApplicationOpen(true);
      setGeneralOpen(false);
    }
  }, [location.pathname]);

  const handleNavigation = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    route: string
  ) => {
    if (isFormDirty) {
      e.preventDefault();
      setRedirectRoute(route); // Set the route clicked
      setShowModal(true);
    }
  };

  const toggleGeneral = () => {
    setGeneralOpen(!generalOpen);
  };

  const toggleApplication = () => {
    setApplicationOpen(!applicationOpen);
  };

  const handleSavechanges = () => {
    setFormDirty(false);
    setShowModal(false);
    formRef.current.handleSubmit();
  };

  const handleLeave = () => {
    if (redirectRoute) {
      // Redirect to the stored route
      setFormDirty(false);
      setShowModal(false);
      navigate(redirectRoute);
    }
  };

  return (
    <>
      <Box>
        <StyleDIV
          onClick={toggleGeneral}
          className={
            location.pathname === ROUTE.SETTINGS ||
            location.pathname === ROUTE.SETTINGS_BILLING_DETAILS ||
            location.pathname === ROUTE.SETTINGS_USERS ||
            location.pathname === ROUTE.SETTINGS_DPO_DETAILS || 
            location.pathname === ROUTE.SETTINGS_BRANDING
              ? "active"
              : ""
          }
        >
          <StyleParagraph>
            <Image
              src={general}
              alt="general icon"
              height="24px"
              width="24px"
              marginRight={"4px"}
            />
            General
          </StyleParagraph>

          <Paragraph my={1}>
            {generalOpen ? (
              <Image
                src={close}
                alt="close"
                height="24px"
                width="24px"
                marginRight={"12px"}
              />
            ) : (
              <Image
                src={open}
                alt="open"
                height="24px"
                width="24px"
                marginRight={"12px"}
              />
            )}
          </Paragraph>
        </StyleDIV>

        {generalOpen && (
          <StyledUl>
            <StyledLi>
              <StyledLink
                to={ROUTE.SETTINGS}
                onClick={(e) => handleNavigation(e, ROUTE.SETTINGS)}
                className={location.pathname === ROUTE.SETTINGS ? "active" : ""}
              >
                Company
              </StyledLink>
            </StyledLi>
            <StyledLi>
              <StyledLink
                to={ROUTE.SETTINGS_BRANDING}
                onClick={(e) => handleNavigation(e, ROUTE.SETTINGS_BRANDING)}
                className={location.pathname === ROUTE.SETTINGS_BRANDING ? "active" : ""}
              >
                Branding
              </StyledLink>
            </StyledLi>
            <StyledLi>
              <StyledLink
                to={ROUTE.SETTINGS_USERS}
                onClick={(e) => handleNavigation(e, ROUTE.SETTINGS_USERS)}
                className={
                  location.pathname === ROUTE.SETTINGS_USERS ? "active" : ""
                }
              >
                Users
              </StyledLink>
            </StyledLi>
            <StyledLi>
              <StyledLink
                to={ROUTE.SETTINGS_BILLING_DETAILS}
                onClick={(e) =>
                  handleNavigation(e, ROUTE.SETTINGS_BILLING_DETAILS)
                }
                className={
                  location.pathname === ROUTE.SETTINGS_BILLING_DETAILS
                    ? "active"
                    : ""
                }
              >
                Billing
              </StyledLink>
            </StyledLi>
            <StyledLi>
              <StyledLink
                onClick={(e) => handleNavigation(e, ROUTE.SETTINGS_DPO_DETAILS)}
                to={ROUTE.SETTINGS_DPO_DETAILS}
                className={
                  location.pathname === ROUTE.SETTINGS_DPO_DETAILS
                    ? "active"
                    : ""
                }
              >
                Data protection officer
              </StyledLink>
            </StyledLi>
          </StyledUl>
        )}

        <StyleDIV
          onClick={toggleApplication}
          className={
            location.pathname.includes(ROUTE.SETTINGS_APPLICATION_TYPES) ||
            location.pathname === ROUTE.SETTINGS_PEP_SANCTION_SCREENING ||
            location.pathname === ROUTE.SETTINGS_SOF_RISK_SCORING ||
            location.pathname === ROUTE.SETTINGS_ADDITIONAL_DOCUMENTS_REQUESTS 
              ? "active"
              : ""
          }
        >
          <StyleParagraph>
            <Image
              src={application}
              alt="indicator icon"
              height="22px"
              width="24px"
              marginRight={"4px"}
            />
            Application
          </StyleParagraph>

          <Paragraph my={1}>
            {applicationOpen ? (
              <Image
                src={close}
                alt="indicator icon"
                height="24px"
                width="24px"
                marginRight={"12px"}
              />
            ) : (
              <Image
                src={open}
                alt="indicator icon"
                height="24px"
                width="24px"
                marginRight={"12px"}
              />
            )}
          </Paragraph>
        </StyleDIV>

        {applicationOpen && (
          <StyledUl>
            <StyledLi>
              <StyledLink
                to={ROUTE.SETTINGS_APPLICATION_TYPES}
                className={
                  location.pathname.includes(ROUTE.SETTINGS_APPLICATION_TYPES)
                    ? "active"
                    : ""
                }
              >
                Application types
              </StyledLink>
            </StyledLi>

            <StyledLi>
              <StyledLink
                to={ROUTE.SETTINGS_PEP_SANCTION_SCREENING}
                className={
                  location.pathname === ROUTE.SETTINGS_PEP_SANCTION_SCREENING
                    ? "active"
                    : ""
                }
              >
                Screening preferences
              </StyledLink>
            </StyledLi>

            <StyledLi>
              <StyledLink
                to={ROUTE.SETTINGS_SOF_RISK_SCORING}
                className={
                  location.pathname === ROUTE.SETTINGS_SOF_RISK_SCORING
                    ? "active"
                    : ""
                }
              >
                Source of funds
              </StyledLink>
            </StyledLi>

            <StyledLi>
              <StyledLink
                to={ROUTE.SETTINGS_ADDITIONAL_DOCUMENTS_REQUESTS}
                className={
                  location.pathname === ROUTE.SETTINGS_ADDITIONAL_DOCUMENTS_REQUESTS
                    ? "active"
                    : ""
                }
              >
                Additional documents requests
              </StyledLink>
            </StyledLi>

            
          </StyledUl>
        )}
      </Box>

      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        width="450px"
        title={"You have unsaved changes"}
        content={
          "You have made changes to your settings that have not been saved. Do you want to save your changes before leaving this page?"
        }
        primaryButtonText="Save"
        primaryButtonVariant="primary"
        secondaryButtonText="Leave"
        secondaryButtonVariant="danger-text"
        hasSecondaryActionButton
        onModalConfirmClick={handleSavechanges}
        onSecondaryButtonClick={handleLeave}
      />
    </>
  );
};

export default LeftMenu;
