import { useMutation } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";

interface Params {
  name: string;
  description: string;
  document_capture_type: number[];
}
export const useUpdateAdditionalDocument = () => {
  const { authHeader } = useAuth();

  return useMutation(({ params, id }: { params: Params; id: number }) => {
    return axios.put(
      `${env.verifyServiceUrl}individual/additional_document/${id}/`,
      params,
      {
        headers: {
          Authorization: authHeader,
        },
      }
    );
  });
};
